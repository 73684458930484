import * as Sentry from '@sentry/react'
import {Integrations} from '@sentry/tracing'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './styles/index.scss'
import {getCurrentStore} from './services/CurrentStoreServices'

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [
        new Integrations.BrowserTracing({
            beforeNavigate: (context) => {
                const storeId = getCurrentStore()
                if (storeId) {
                    Sentry.setTag('storeId', storeId)
                }
                return {
                    ...context,
                }
            },
        }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.25,
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

