import React from 'react'
import {useSelectedVariantContext} from '../../../context/SelectedVariantContext'
import ProductAttributes from '../../../product-variant/ProductAttributes'

const VariantViewContainer = () => {
    const {
        state: {mappedAttributes},
    } = useSelectedVariantContext()

    const _getSelectSize = () => {
        const selectSize = mappedAttributes.size.find((attr) => attr.is_selected)
        return selectSize.value_text
    }

    return (
        <div className="VariantViewContainer px-3">
            {'size' in mappedAttributes && (
                <>
                    <div>The mockup is for "size {_getSelectSize()}"</div>
                    <div>
                        The visible design area may be proportionately larger or smaller to match the final print size
                    </div>
                </>
            )}
            <div className="ListProductLineConfirm">
                <div className="ItemCatalog View">
                    <div className="ContentItem p-0">
                        <div className="ProductVariants">
                            <ProductAttributes />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VariantViewContainer

